'use client';

import { hasSupportedChain } from '@frontend/common';
import { AvatarList } from '@frontend/components/common/AvatarList';
import JustanameAddressInput from '@frontend/components/JustanameAddressInput';
import parseTokenImages from '@frontend/utils/parseImages';
import { splitDomain, useRevokeSubname } from '@justaname.id/react';
import { Badge, Button, Card, Flex, Grid, Text } from '@radix-ui/themes';
import { getChain, getTokens, TokenInfo } from '@yodlpay/tokenlists';
import Image from 'next/image';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { useDebounce } from '@frontend/client/hooks';
import useAccountEns from '@frontend/client/hooks/useAccountEns';
import {
  useSignedPreferences,
  useSignPreferences,
} from '@frontend/client/hooks/useSignedPreferences';
import {
  ALL_PREFERENCE_SECTIONS,
  isUSDStableSymbol,
  PreferenceSection,
  SUPPORTED_CHAINS,
  YODL_ENS_DOMAIN,
} from '@frontend/common';
import ChainSelector from '@frontend/components/ChainSelector/index';
import { safeWindow } from '@frontend/utils/safeWindow';
import { isEqualsIgnoreCase } from '@frontend/utils/string';
import assert from 'assert';
import { mainnet } from 'viem/chains';
import { useAccount, useSwitchChain } from 'wagmi';

const IMAGE_SIZE = 8 * 4;

const savePreferencesButtonColorMap = {
  success: 'grass',
  error: 'red',
};

const savePreferencesButtonText = {
  idle: 'Confirm',
  success: 'Close',
  error: 'Try again',
};

interface PaymentPreferencesSectionProps {
  handleOnClose: () => void;
}

// Add reusable card component
interface CardData {
  id?: string | number;
  logoUri?: string;
  name?: string;
  chainName?: string;
  chainId?: number;
  symbol?: string;
}

const CardComponent = ({
  data,
  isSelected,
  onClick,
  avatarUris,
}: {
  data: CardData;
  isSelected: boolean;
  onClick: () => void;
  avatarUris: string[];
}) => (
  <Card
    className={`cursor-pointer transition-all duration-150 no-scrollbar ${
      isSelected ? 'ring-1 ring-violet-500 shadow-md' : 'hover:shadow-md'
    }`}
    onClick={onClick}
  >
    <Flex align="center" justify="between">
      <Flex align="center" gap="3">
        <Image
          width={IMAGE_SIZE}
          height={IMAGE_SIZE}
          src={data.logoUri || ''}
          alt={data.chainName || data.name || ''}
          className="rounded-full"
        />
        <Grid gap="1">
          <Text size="2" weight="medium">
            {data.chainName || data.name}
          </Text>
          <Text size="1" color="gray">
            {data.symbol || data.chainId}
          </Text>
        </Grid>
      </Flex>
      <Flex align="center">
        {avatarUris.length > 0 && <AvatarList size={24} uris={avatarUris} />}
      </Flex>
    </Flex>
  </Card>
);

const PaymentPreferencesSection = ({
  handleOnClose,
}: PaymentPreferencesSectionProps) => {
  const { address, chainId } = useAccount();
  const { switchChain, isPending: switchChainPending } = useSwitchChain();
  const allTokens = getTokens();

  const { primaryName, isPrimaryNameLoading: isLoading } = useAccountEns();
  const [username, setUsername] = useState(primaryName || '');
  const debouncedUsername = useDebounce(username, 500);

  const [isValid, setIsValid] = useState(false);

  const [selectedTokens, setSelectedTokens] = useState<TokenInfo[]>([]);
  const [selectedChains, setSelectedChains] = useState<number[]>([]);
  const [selectedSection, setSelectedSection] =
    useState<PreferenceSection>('all');

  const { preferences } = useSignedPreferences({
    addressOrEns: address,
  });

  const { mutate, status, error } = useSignPreferences({
    username: debouncedUsername.debouncedValue,
    isRegistered: !!primaryName,
  });

  const tokensMap = useMemo(() => {
    const supportedTokens = allTokens.filter((token) => {
      return isUSDStableSymbol(token.symbol) && hasSupportedChain(token);
    });

    // remove duplicates
    const uniqueTokens = supportedTokens.filter(
      (token, index, self) =>
        index ===
        self.findIndex((t) => isEqualsIgnoreCase(t.symbol, token.symbol)),
    );

    // Add the chain id to the token if it's same
    return uniqueTokens.map((token) => {
      const chainId = token.chainId;
      const chains = supportedTokens
        .filter((t) => isEqualsIgnoreCase(t.symbol, token.symbol))
        .map((t) => t.chainId)
        .map((chainId) => getChain(chainId));

      return {
        ...token,
        chainId,
        chains,
      };
    });
  }, [allTokens]);

  const filteredTokens = useMemo(() => {
    // Filter tokens based on the selected section
    const currentSection = ALL_PREFERENCE_SECTIONS[selectedSection];
    return tokensMap.filter((token) => {
      return (
        selectedSection === 'all' ||
        currentSection?.symbols.includes(token.symbol.toUpperCase() as any)
      );
    });
  }, [tokensMap, selectedSection]);

  const isAcceptableChain = useMemo(() => chainId === mainnet.id, [chainId]);

  const handleSendSignedPreferences = useCallback(async () => {
    assert(address, 'No address');

    const uniqueTokenSymbols = selectedTokens.map((t) => t.symbol);

    // Remove chainIds and tokenSymbols from preferences to avoid duplicates
    const { chainIds, tokenSymbols, ...restPreferences } = preferences || {};

    mutate({
      chains: selectedChains,
      tokens: uniqueTokenSymbols,
      ...restPreferences,
    });
  }, [address, mutate, selectedChains, selectedTokens, preferences]);

  const { revokeSubname, isRevokeSubnamePending } = useRevokeSubname({
    chainId: 1,
    ensDomain: YODL_ENS_DOMAIN,
  });

  // Update the selected cards with the new preferences
  useEffect(() => {
    const tokenSymbols = preferences?.tokenSymbols || [];
    const chainIds = preferences?.chainIds || [];
    const selectedTokens = tokenSymbols
      .map((symbol) =>
        allTokens.find((t) => isEqualsIgnoreCase(t.symbol, symbol)),
      )
      .filter((t) => t !== undefined);
    const selectedChains = chainIds.filter((id) =>
      SUPPORTED_CHAINS.some((chain) => chain.id === id),
    );

    setSelectedTokens(selectedTokens);
    setSelectedChains(selectedChains);
  }, [allTokens, preferences]);

  // After payment preferences are saved, close the dialog
  useEffect(() => {
    if (status === 'success') {
      handleOnClose();
    }
  }, [status, handleOnClose]);

  return (
    <>
      <JustanameAddressInput
        isLoading={isLoading}
        revokeSubnameMutation={() => {
          const [username, ensDomain] = splitDomain(
            debouncedUsername.debouncedValue,
          );

          revokeSubname({
            username,
            ensDomain,
          }).then(() => {
            safeWindow?.location.reload();
          });
        }}
        onUsernameChange={setUsername}
        currentUsername={primaryName}
        isUsernameSet={Boolean(primaryName)}
        isValid={setIsValid}
      />

      <Flex gap="2" justify="between" align="center">
        <Flex gap="2">
          {Object.keys(ALL_PREFERENCE_SECTIONS).map((section) => {
            return (
              <Badge
                key={section}
                radius="full"
                color={selectedSection.includes(section) ? 'violet' : 'gray'}
                variant="soft"
                size="2"
                className="cursor-pointer"
                onClick={() => setSelectedSection(section as PreferenceSection)}
              >
                {ALL_PREFERENCE_SECTIONS[section].name}
              </Badge>
            );
          })}
        </Flex>
        <ChainSelector
          onChange={(selectedChains) => {
            setSelectedChains(selectedChains.map(Number));
          }}
          selectedChains={selectedChains.map(String)}
        />
      </Flex>

      {ALL_PREFERENCE_SECTIONS[selectedSection]?.description && (
        <Text size="1" color="gray">
          {ALL_PREFERENCE_SECTIONS[selectedSection]?.description}
        </Text>
      )}

      {error?.message && (
        <Text size="1" color="red">
          {error.message}
          {error.name}
        </Text>
      )}

      <Grid gap="2" p="1">
        {filteredTokens.map((token, index) => {
          const isSelected = selectedTokens.some(
            (t) => t.symbol === token.symbol,
          );
          const chainImages = token.chains
            .map(({ chainId }) => parseTokenImages(getChain(chainId).logoUri))
            .filter((uri) => uri !== undefined);

          return (
            <CardComponent
              key={index}
              data={token}
              isSelected={isSelected}
              onClick={() => {
                setSelectedTokens((prev) =>
                  isSelected
                    ? prev.filter((t) => t.symbol !== token.symbol)
                    : [...prev, token],
                );
              }}
              avatarUris={chainImages}
            />
          );
        })}
      </Grid>

      <Button
        size="3"
        radius="full"
        onClick={() => {
          if (isAcceptableChain) {
            handleSendSignedPreferences();
          } else {
            switchChain({ chainId: mainnet.id });
          }
        }}
        loading={
          status === 'pending' ||
          switchChainPending ||
          isLoading ||
          isRevokeSubnamePending
        }
        color={savePreferencesButtonColorMap[status]}
        disabled={
          !selectedTokens.length ||
          !username ||
          !isValid ||
          status === 'pending' ||
          switchChainPending ||
          isLoading ||
          isRevokeSubnamePending
        }
      >
        {isAcceptableChain
          ? selectedTokens.length
            ? savePreferencesButtonText[status]
            : 'Select tokens'
          : 'Switch to Mainnet'}
      </Button>
    </>
  );
};

export default PaymentPreferencesSection;
