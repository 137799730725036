import { SUPPORTED_CHAINS } from '@frontend/common';
import { chainById } from '@frontend/utils/chains';
import { ChevronDownIcon } from '@radix-ui/react-icons';
import * as Popover from '@radix-ui/react-popover';
import { Button, Card, Flex } from '@radix-ui/themes';
import Image from 'next/image';
import { useState } from 'react';
import { ChainItem } from './ChainItem';
import { ChainSelectorProps } from './types';
import { useChainSelection } from './useChainSelection';

const ChainSelector = ({
  onChange,
  selectedChains: initialSelectedChains,
}: ChainSelectorProps) => {
  const { selectedChains, handleChainToggle, commonChainLogos } =
    useChainSelection(onChange, initialSelectedChains);
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      {isOpen && (
        <div
          className="fixed inset-0 bg-black/30"
          style={{ zIndex: 40 }}
          onClick={() => setIsOpen(false)}
        />
      )}

      <Popover.Root open={isOpen} onOpenChange={setIsOpen}>
        <Popover.Trigger>
          <Button
            radius="large"
            variant="ghost"
            color="gray"
            size="2"
            className="!m-0 "
          >
            <Flex align="center" gap="2" height="24px">
              <div
                className={`grid ${commonChainLogos.length === 1 ? '' : 'grid-cols-2'} gap-0.5 rounded-[4px] shadow-sm`}
              >
                {commonChainLogos.map((uri, index) => {
                  const imageSize =
                    commonChainLogos.length === 1
                      ? 22
                      : commonChainLogos.length === 2
                        ? 16
                        : 11;

                  return (
                    <div key={`${uri}-${index}`}>
                      <Image
                        src={uri}
                        alt=""
                        width={imageSize}
                        height={imageSize}
                      />
                    </div>
                  );
                })}
              </div>
              <ChevronDownIcon />
            </Flex>
          </Button>
        </Popover.Trigger>

        <Popover.Content
          side="bottom"
          sideOffset={4}
          align="end"
          style={{
            zIndex: 50,
            backgroundColor: 'var(--gray-1)',
            borderRadius: 'var(--radius-4)',
          }}
        >
          <Card
            style={{ minWidth: '200px', borderRadius: 'var(--radius-4)' }}
            className="!shadow-md"
          >
            <Flex direction="column" gap="1">
              <ChainItem
                key="all-chains"
                chainId="all"
                chainName="All Chains"
                customLogo={
                  <div className="grid grid-cols-2 gap-0.5 rounded-[4px] shadow-sm">
                    {SUPPORTED_CHAINS.slice(0, 4).map((chain) => {
                      const { chainInfo } = chainById(chain.id);
                      return (
                        <div key={chain.id}>
                          <Image
                            src={chainInfo.logoUri}
                            alt=""
                            width={10}
                            height={10}
                          />
                        </div>
                      );
                    })}
                  </div>
                }
                isSelected={!selectedChains.length}
                onToggle={() => handleChainToggle('all')}
              />
              {SUPPORTED_CHAINS.map((chain) => {
                const { chainInfo } = chainById(chain.id);
                return (
                  <ChainItem
                    key={chain.id}
                    chainId={chain.id.toString()}
                    chainName={chainInfo.chainName}
                    logoUri={chainInfo.logoUri}
                    isSelected={selectedChains.includes(chain.id.toString())}
                    onToggle={handleChainToggle}
                  />
                );
              })}
            </Flex>
          </Card>
        </Popover.Content>
      </Popover.Root>
    </>
  );
};

export default ChainSelector;
