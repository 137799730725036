'use client';

import useWalletChangeHandler from '@frontend/client/hooks/useWalletChangeHandler';

/**
 * Component that handles wallet changes and resets tokenIn when the wallet address changes.
 * This component doesn't render anything, it just uses the hook.
 */
export const WalletChangeHandler = () => {
  useWalletChangeHandler();

  // This component doesn't render anything
  return null;
};

export default WalletChangeHandler;
