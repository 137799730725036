'use client';

import '@frontend/utils/jsonSerialize';

import UserStatePopupProvider from '@frontend/app/shared/providers/UserStatePopupProvider';
import WalletChangeHandler from '@frontend/client/components/WalletChangeHandler';
import { useYodlStore } from '@frontend/client/contexts/useYodlStore';
import { generateWagmiConfig } from '@frontend/client/rainbowkit';
import { DOMAIN, ORIGIN, YODL_ENS_DOMAIN } from '@frontend/common';
import { defaultPublicRpc } from '@frontend/utils/env.client';
import { safeWindow } from '@frontend/utils/safeWindow';
import { yodlPaymasterUrl } from '@frontend/utils/yodlPaymasterUrl';
import {
  JustaNameProvider,
  JustaNameProviderConfig,
} from '@justaname.id/react';
import { darkTheme, RainbowKitProvider } from '@rainbow-me/rainbowkit';
import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { persistQueryClient } from '@tanstack/react-query-persist-client';
import { TransactionModal, TransactionProvider } from 'ethereum-identity-kit';
import { AppProgressBar as ProgressBar } from 'next-nprogress-bar';
import { useTheme } from 'next-themes';
import posthog from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';
import { ReactNode, useEffect, useMemo } from 'react';
import { Address } from 'viem';
import { base, mainnet } from 'viem/chains';
import { cookieToInitialState, WagmiProvider } from 'wagmi';
import SafeAblyProvider from './SafeAblyProvider';

if (
  typeof safeWindow !== 'undefined' &&
  process.env.NODE_ENV !== 'development'
) {
  if (
    !process.env.NEXT_PUBLIC_POSTHOG_KEY ||
    !process.env.NEXT_PUBLIC_POSTHOG_HOST
  ) {
    console.error('Posthog not configured');
  }

  posthog.init(process.env.NEXT_PUBLIC_POSTHOG_KEY!, {
    api_host: process.env.NEXT_PUBLIC_POSTHOG_HOST,
    capture_pageleave: true, // Enable automatic pageleave capture
  });
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      gcTime: 1000 * 60 * 60 * 24, // 24 hours
      staleTime: 0,
    },
  },
});

const localStoragePersister = createSyncStoragePersister({
  storage: safeWindow?.localStorage,
});
// const sessionStoragePersister = createSyncStoragePersister({ storage: window.sessionStorage })

persistQueryClient({
  queryClient,
  persister: localStoragePersister,
  dehydrateOptions: {
    shouldDehydrateQuery: (query) => {
      const oneDayMs = 1000 * 60 * 60 * 24; // 24 hours in milliseconds
      const isNewer = Date.now() - query.state.dataUpdatedAt < oneDayMs;
      return Boolean(query.meta?.enablePersist) && isNewer;
    },
  },
  maxAge: 1000 * 60 * 60 * 24, // 24 hours
});

const justweb3Config: JustaNameProviderConfig = {
  config: {
    origin: ORIGIN,
    domain: DOMAIN,
    signInTtl: 120000,
  },
  ensDomains: [
    {
      chainId: mainnet.id,
      ensDomain: YODL_ENS_DOMAIN,
    },
  ],
  networks: [
    {
      chainId: mainnet.id,
      providerUrl: defaultPublicRpc(1),
    },
  ],
};

type ProvidersProps = {
  children: ReactNode;
  initialCookies: string | null | undefined;
  // isDapp=true if interactive, e.g. if part of a dapp.
  // isDapp=false if not interactive content, e.g. landers, tx explorer
  isDapp: boolean;
};

export function Providers({
  children,
  initialCookies,
  isDapp,
}: ProvidersProps) {
  const playwright = useYodlStore((state) => state.playwright);
  const setPlaywright = useYodlStore((state) => state.setPlaywright);
  const rainbowTheme = darkTheme();

  useEffect(() => {
    // DO NOT use the useSearchParams hook`
    // It causes Rainbowkit to disconnect from the user's wallter for unknown reasons
    // the following code breaks the Rainbowkit button
    // const urlParams = useSearchParams();

    const urlParams = new URLSearchParams(safeWindow?.location.search);
    const playwrightParam = urlParams.get('playwright');

    // set refid in localstorage
    const refid = urlParams.get('refid');
    if (refid && refid != '') {
      // do not overwrite refids, first refid counts.
      if (!localStorage.getItem('refid')) {
        localStorage.setItem('refid', refid);
      }
    }

    if (playwrightParam) {
      setPlaywright(playwrightParam as Address);
    }
  }, [setPlaywright]);

  const wagmiConfig = useMemo(
    () =>
      generateWagmiConfig({
        playwright,
      }),
    [playwright],
  );

  // initialCookies see: https://wagmi.sh/react/guides/ssr
  const initialState = useMemo(
    () => cookieToInitialState(wagmiConfig, initialCookies),
    [wagmiConfig, initialCookies],
  );

  const childrenWrapped = isDapp ? (
    <SafeAblyProvider>
      <UserStatePopupProvider>{children}</UserStatePopupProvider>
    </SafeAblyProvider>
  ) : (
    children
  );

  const content = (
    <PostHogProvider client={posthog}>
      {childrenWrapped}
      <ProgressBar
        height="4px"
        options={{ showSpinner: false }}
        shallowRouting
      />
    </PostHogProvider>
  );

  const { theme } = useTheme();
  const paymasterUrl = yodlPaymasterUrl();

  return (
    <QueryClientProvider client={queryClient}>
      <WagmiProvider
        config={wagmiConfig}
        reconnectOnMount={true}
        initialState={initialState}
      >
        <RainbowKitProvider theme={rainbowTheme}>
          <TransactionProvider
            paymasterService={paymasterUrl}
            defaultChainId={base.id}
          >
            <AutoConnectProvider />
            <WalletChangeHandler />
            <TransactionModal darkMode={theme === 'dark'} />

            <JustaNameProvider config={justweb3Config}>
              {content}
            </JustaNameProvider>
          </TransactionProvider>
        </RainbowKitProvider>
      </WagmiProvider>
    </QueryClientProvider>
  );
}

function AutoConnectProvider() {
  return null;
}
