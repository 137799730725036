import { SUPPORTED_CHAINS } from '@frontend/common';
import { chainById } from '@frontend/utils/chains';
import { useEffect, useMemo, useState } from 'react';

export const COMMON_CHAINS = SUPPORTED_CHAINS.slice(0, 4);

export const useChainSelection = (
  onChange?: (selectedChains: string[]) => void,
  initialSelectedChains?: string[],
) => {
  const [selectedChains, setSelectedChains] = useState<string[]>(
    initialSelectedChains || [],
  );

  useEffect(() => {
    if (initialSelectedChains?.length) {
      setSelectedChains(initialSelectedChains);
    }
  }, [initialSelectedChains]);

  const handleChainToggle = (chainId: string) => {
    if (chainId === 'all') {
      setSelectedChains([]);
      onChange?.([]);
      return;
    }

    const isSelected = selectedChains.includes(chainId);
    const updatedChains = isSelected
      ? selectedChains.filter((id) => id !== chainId)
      : [...selectedChains, chainId];

    setSelectedChains(updatedChains);
    onChange?.(updatedChains);
  };

  const commonChainLogos = useMemo(
    () =>
      (selectedChains.length ? selectedChains : COMMON_CHAINS)
        .slice(0, 4)
        .map((chain) => chainById(Number(chain.id || chain)).chainInfo.logoUri),
    [selectedChains],
  );

  return {
    selectedChains,
    handleChainToggle,
    commonChainLogos,
  };
};
