import { cn } from '@frontend/utils/tailwindUtil';
import { Flex, Text } from '@radix-ui/themes';
import Image from 'next/image';
import { IoIosCheckmarkCircle } from 'react-icons/io';
import { ChainItemProps } from './types';

export const ChainItem = ({
  chainId,
  chainName,
  logoUri,
  isSelected,
  onToggle,
  customLogo,
  disabled,
}: ChainItemProps) => (
  <Flex
    align="center"
    gap="2"
    justify="between"
    onClick={() => onToggle?.(chainId, !isSelected)}
    p="1"
    className={cn(
      'cursor-pointer transition-colors duration-75 hover:bg-gray-100 dark:hover:bg-gray-800',
      disabled && 'cursor-not-allowed opacity-50 rounded-md',
    )}
  >
    <Flex align="center" gap="2">
      {customLogo || (
        <Image src={logoUri || ''} alt={chainName} width={20} height={20} />
      )}
      <Text size="2" weight="medium">
        {chainName}
      </Text>
    </Flex>
    {isSelected && <IoIosCheckmarkCircle />}
  </Flex>
);
