'use client';

import { useYodlStore } from '@frontend/client/contexts/useYodlStore';
import { useEffect, useRef } from 'react';
import { useAccount } from 'wagmi';

/**
 * Hook to handle wallet changes and reset tokenIn when the wallet address changes
 */
export const useWalletChangeHandler = () => {
  const { address } = useAccount();
  const previousAddress = useRef<string | undefined>(undefined);

  const setTokenIn = useYodlStore((state) => state.setTokenIn);
  const setTokenSelection = useYodlStore((state) => state.setTokenSelection);
  const setSwapQuote = useYodlStore((state) => state.setSwapQuote);
  const setTokenOut = useYodlStore((state) => state.setTokenOut);

  useEffect(() => {
    // If the address has changed and we had a previous address (not initial load)
    if (
      address !== previousAddress.current &&
      previousAddress.current !== undefined
    ) {
      // Reset tokenIn when wallet changes
      setTokenIn(undefined);
      setTokenSelection(undefined);
      setSwapQuote(undefined);
      setTokenOut(undefined);
    }

    // Update the previous address reference
    previousAddress.current = address as string | undefined;
  }, [address, setTokenIn, setTokenSelection, setSwapQuote, setTokenOut]);
};

export default useWalletChangeHandler;
